"use client"

import type { Nullish } from "~/@types/generics"
import type { SbNavigationTheme } from "~/lib/storyblok/types"
import { useNavigationTheme } from "~/components/abstracts/Navigation/hooks"

type NavigationThemeSetterProps = { theme: Nullish<SbNavigationTheme> }

function NavigationThemeSetter({ theme }: NavigationThemeSetterProps) {
  const [, setNavigationTheme] = useNavigationTheme()

  setNavigationTheme(theme ?? "grey-alabaster")

  return null
}

export { NavigationThemeSetter, type NavigationThemeSetterProps }
