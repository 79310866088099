import { useEffect } from "react"
import { usePathname, useSearchParams } from "next/navigation"
import { useAtom } from "jotai"

import { NavigationMobileVisibility, NavigationTheme, panelIndexAtom } from "~/components/abstracts/Navigation/store"

export function usePanelIndex() {
  return useAtom(panelIndexAtom)
}

export function useNavigationMobileVisibility() {
  return useAtom(NavigationMobileVisibility)
}

export function useNavigationTheme() {
  return useAtom(NavigationTheme)
}

export function useNavigationEvents(callBack?: () => void) {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    callBack?.()
  }, [pathname, searchParams])

  return null
}
